import {useScramble} from 'use-scramble';

type ScrambleProps = {
  children: string;
  className?: string;
};

export const Scramble = ({children, className, ...props}: ScrambleProps) => {
  const {ref, replay} = useScramble({
    text: children,
    ...props,
  });

  return (
    <span
      className={className}
      ref={ref}
      onMouseOver={replay}
      onFocus={replay}
    />
  );
};
